import {
    closeAnimation,
    mobileCloseAnimation,
    mobileMouseEnter, mobileMouseLeave,
    mobileOpenAnimation, mouseEnter, mouseLeave,
    openAnimation
} from "./sidebar-animations.js";

export const setEventHandlers = () => {
    const sidebar = document.querySelector('[data-sidebar]');

    if (!sidebar) {
        return;
    }

    const sidebarElements = Array.from(sidebar.children);

    const sidebarElementsWithoutNavButton = [...sidebarElements].slice(0, -1);

    const sidebarElementsWithoutNavButtonReversed = [...sidebarElementsWithoutNavButton].reverse();

    const navButton = sidebarElements.at(-1);

    const sidebarToggle = sidebar.querySelector('[data-sidebar-toggle]');
    const iconWrapper = sidebarToggle.querySelector('[data-sidebar-icons]');
    const pillIcon = iconWrapper.querySelector('[data-sidebar-pill]');
    const xIcon = iconWrapper.querySelector('[data-sidebar-x]');

    //Click handler
    navButton.addEventListener('click', async () => {
        const isMobile = window.screen.width <= 768;

        if (navButton.classList.contains('animation-active')) {
            return;
        }

        navButton.classList.add('animation-active');

        if (navButton.classList.contains('closed')) {
            navButton.classList.remove('closed');
            navButton.classList.add('open');

            sidebarToggle.classList.remove('md:w-20');
            sidebarToggle.classList.add('md:w-12');

            iconWrapper.classList.remove('w-20');
            iconWrapper.classList.add('w-12');

            pillIcon.classList.add('hidden');
            xIcon.classList.remove('hidden');

            if (isMobile) {
                await mobileOpenAnimation(navButton.children[0]);
            }

            for (const element of sidebarElementsWithoutNavButtonReversed) {
                if (!isMobile) {
                    element.classList.remove('md:hidden')
                }
                await openAnimation(element);
            }

        } else {
            navButton.classList.remove('open');
            navButton.classList.add('closed');

            for (const element of sidebarElementsWithoutNavButton) {
                await closeAnimation(element);
                if (!isMobile) {
                    element.classList.add('md:hidden')
                }
            }

            if (isMobile) {
                await mobileCloseAnimation(navButton.children[0])
            }

            sidebarToggle.classList.add('md:w-20');
            sidebarToggle.classList.remove('md:w-12');

            iconWrapper.classList.remove('w-12');
            iconWrapper.classList.add('w-20');

            pillIcon.classList.remove('hidden');
            xIcon.classList.add('hidden');
        }
        navButton.classList.remove('animation-active');
    });

    //Hover handler
    for (const element of sidebarElementsWithoutNavButton) {
        element.addEventListener('mouseenter', () => {
            const isMobile = window.screen.width <= 768;
            if (isMobile) {
                mobileMouseEnter(element);
            } else {
                element.querySelector('[data-sidebar-text]').classList.add('md:inline-block');
                mouseEnter(element.querySelector('[data-sidebar-text]'))
            }
        });
        element.addEventListener('mouseleave', async () => {
            const isMobile = window.screen.width <= 768;
            if (isMobile) {
                mobileMouseLeave(element);
            } else {
                await mouseLeave(element.querySelector('[data-sidebar-text]'))
                element.querySelector('[data-sidebar-text]').classList.remove('md:inline-block');
            }
        });
    }
}
