import gsap from "./gsap";

window.addEventListener("load", (event) => {
    initScrollers();
});
const initScrollers = () => {
    const scrollers = document.querySelectorAll('[data-brick="scroller"]');
    const page = document.querySelector("#page");
    scrollers.forEach((scroller, i) => {
        const timeline = gsap.timeline({});
        const slides = gsap.utils.toArray("article", scroller);

        const mainScroll = gsap.to(slides, {
            yPercent: -100 * (slides.length - 1),
            ease: "none",
            // duration: 4,
            scrollTrigger: {
                snap: 1 / (slides.length - 1),
                pinSpacing: true,
                trigger: scroller,
                pin: page,
                scrub: 1,
                // (Page height - Element height + Sticky Nav height) / 2 === centered scroller
                start: "top top+=" + ((window.innerHeight - scroller.offsetHeight + 60) / 2) + "px",
                end: "+=" + scroller.offsetHeight * (slides.length - 1),
            },
        });

        slides.forEach((slide, i) => {
            /** @var {Element} slide */
            const left = slide.querySelector("div:nth-child(1)");
            const right = slide.querySelector("div:nth-child(2)");

            gsap.fromTo(
                right,
                {
                    opacity: 0,
                    scale: 0.2,
                    xPercent: 100,
                },
                {
                    opacity: 1,
                    scale: 1,
                    xPercent: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: slide,
                        scrub: 1,
                        end: "top 20%",
                    },
                },
            );

            gsap.fromTo(
                left,
                {
                    opacity: 0,
                    scale: 0.2,
                    xPercent: -100,
                },
                {
                    opacity: 1,
                    scale: 1,
                    xPercent: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: slide,
                        scrub: 1,
                        end: "top 20%",
                    },
                },
            );
        });
    });
}