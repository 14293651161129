import * as htmx from 'htmx.org'

htmx.config.includeIndicatorStyles = false
window.htmx = htmx

import './menu.js'
import './animations'
import './sliders'
import './details-summary-chevron.js'
import './automations.js'
import './finder'
import './large-slider'
import './accordion.js'
import './form-result.js'
import './show-more-elements.js'
import './photoswipe.js'
import './language-picker.js'
import './sidebar'
import './crm.js'

import './../../vendor/wire-elements/modal/resources/js/modal.js'
