import { setKeydownListeners } from './filter-keydown-handler.js'
import { initFilterRangeInput } from './filter-range-input.js'
import { filterboxDropdown } from './filterbox.js'
import './history-replacer.js'

//Checkbox a11y:
setKeydownListeners()

//Range input init:
initFilterRangeInput()

filterboxDropdown()
