const palettButton = document.querySelector('[data-palett-button]')

if (palettButton) {
    const palettChevronDown = palettButton.querySelector('[data-palett-chevron-down]')
    const palettChevronUp = palettButton.querySelector('[data-palett-chevron-up]')

    const workpieceButton = document.querySelector('[data-workpiece-button]')
    const workpieceChevronDown = workpieceButton.querySelector('[data-workpiece-chevron-down]')
    const workpieceChevronUp = workpieceButton.querySelector('[data-workpiece-chevron-up]')

    const palett = document.querySelector('[data-palett-automation]')
    const workpiece = document.querySelector('[data-workpiece-automation]')

    window.onload = () => {
        workpiece.classList.add('hidden')
    }
    palettButton.addEventListener('click', () => {
        if (palettButton.classList.contains('active-automation')) {
            return
        }

        palettButton.classList.add('active-automation')
        palettButton.classList.remove('cursor-pointer')
        palettButton.classList.add('cursor-default')
        palettChevronDown.classList.remove('hidden')
        palettChevronUp.classList.add('hidden')

        workpieceButton.classList.remove('active-automation')
        workpieceButton.classList.remove('cursor-default')
        workpieceButton.classList.add('cursor-pointer')
        workpieceChevronDown.classList.add('hidden')
        workpieceChevronUp.classList.remove('hidden')

        palett.classList.remove('hidden')
        workpiece.classList.add('hidden')
    })
    workpieceButton.addEventListener('click', () => {
        if (workpieceButton.classList.contains('active-automation')) {
            return
        }

        workpieceButton.classList.add('active-automation')
        workpieceButton.classList.remove('cursor-pointer')
        workpieceButton.classList.add('cursor-default')
        workpieceChevronDown.classList.remove('hidden')
        workpieceChevronUp.classList.add('hidden')

        palettButton.classList.remove('active-automation')
        palettButton.classList.remove('cursor-default')
        palettButton.classList.add('cursor-pointer')
        palettChevronDown.classList.add('hidden')
        palettChevronUp.classList.remove('hidden')

        workpiece.classList.remove('hidden')
        palett.classList.add('hidden')
    })

    const sliders = Array.from(document.querySelectorAll('.snap-slider'))

    for (const slider of sliders) {
        const prev = slider.parentNode.querySelector('.prev')
        const next = slider.parentNode.querySelector('.next')

        if (prev && next) {
            prev.addEventListener('click', () => {
                slider.scrollBy({left: -slider.offsetWidth / 4, behavior: 'smooth'})
            })

            next.addEventListener('click', () => {
                slider.scrollBy({left: slider.offsetWidth / 4, behavior: 'smooth'})
            })
        }

        const slideImgs = Array.from(slider.querySelectorAll('img'))

        for (const image of slideImgs) {
            image.setAttribute('draggable', 'false')
        }
    }
}
