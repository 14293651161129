import gsap from './gsap'

const container = document.querySelector('#sticky-nav')
const page = document.querySelector('#page')

if (container) {
    const logo = container.querySelector('[data-logo]')
    const buttons = container.querySelector('[data-buttons]')

    gsap.to(logo, {
        opacity: 1,
        marginLeft: 0,
        visibility: 'visible',
        scrollTrigger: {
            trigger: container,
            pinnedContainer: page,
            start: 'top top',
            toggleActions: 'restart none none reverse',
        },
    })

    gsap.to(buttons, {
        opacity: 1,
        marginRight: 0,
        visibility: 'visible',
        scrollTrigger: {
            trigger: container,
            pinnedContainer: page,
            start: 'top top',
            toggleActions: 'restart none none reverse',
        },
    })
}
